import * as React from 'react';

import Footer from 'styleguide/components/Footer/Footer';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import css from './HoursAndLocation.scss';
import TitleContainer from 'bundles/App/pages/Account/TitleContainer/TitleContainer';
import Meta from 'styleguide/components/Meta/Meta';

const HoursAndLocation = () => (
  <>
    <Meta
      title="Hours and Location | Printivity"
      description="Printivity is available to call or drop by for pick up on Monday-Friday, 9:30am - 8pm Eastern Standard Time. Our main office is at 8840 Kenamar Dr. San Diego, CA 92121,"
      canonical="https://www.printivity.com/hours-and-location"
    />
    <TitleContainer title="Hours and Location" />
    <Grid.Container>
      <Grid>
        <Grid.Row className={css.container}>
          <Grid.Col>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.title}>Hours</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.text}>
                  Our office hours are Monday-Friday, 9:30am - 8pm Eastern Standard Time.
                </Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.title}>Location</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.text}>Our office is located in Miramar, San Diego:</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.text}>
                  Printivity
                  <br />
                  8840 Kenamar Dr.
                  <br />
                  Suite 405
                  <br />
                  San Diego, CA 92121
                  <br />
                  (858) 679-6700
                </Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.title}>Directions</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <Span className={css.text}>
                  We are located in a green office building 3 miles west of the Pomerado Rd/Miramar Rd exit on
                  I-15.
                </Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className={css.center}>
                <div id="map-container">
                  <iframe
                    title="location"
                    width="600px"
                    height="350px"
                    scrolling="no"
                    src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=8840+Kenamar+Dr,+San+Diego,+CA&amp;aq=0&amp;oq=8840+kenamar+dr,&amp;sll=37.6,-95.665&amp;sspn=48.675258,107.138672&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=8840+Kenamar+Dr,+San+Diego,+California+92121&amp;ll=32.892129,-117.154169&amp;spn=0.025225,0.051498&amp;z=14&amp;iwloc=A&amp;output=embed"
                  />
                  <br />
                  <br />
                  <a
                    className={css.mapsUrl}
                    href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=8840+Kenamar+Dr,+San+Diego,+CA&amp;aq=0&amp;oq=8840+kenamar+dr,&amp;sll=37.6,-95.665&amp;sspn=48.675258,107.138672&amp;t=m&amp;ie=UTF8&amp;hq=&amp;hnear=8840+Kenamar+Dr,+San+Diego,+California+92121&amp;ll=32.892129,-117.154169&amp;spn=0.025225,0.051498&amp;z=14&amp;iwloc=A"
                  >
                    Go to Google Maps for Directions
                  </a>
                  <br />
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
    <Footer />
  </>
);

export default HoursAndLocation;
